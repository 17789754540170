import { render, staticRenderFns } from "./contracts-list.vue?vue&type=template&id=661e38e6&scoped=true"
import script from "./contracts-list.vue?vue&type=script&lang=js"
export * from "./contracts-list.vue?vue&type=script&lang=js"
import style0 from "./contracts-list.vue?vue&type=style&index=0&id=661e38e6&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "661e38e6",
  null
  
)

export default component.exports